import { createAsyncThunk } from "@reduxjs/toolkit"
import { FC, lazy, useEffect, useState } from "react"
import { RootState } from "../../app/store"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectUpdateStoksPodruzhkaStore,
  setPodruzhkaOrders,
} from "../../app/reducers/updateStoksPodruzhka"
import BaseApi from "../../api/api"

const getPodrOrders = createAsyncThunk<
  Promise<void>,
  void,
  { state: RootState }
>("updateStoksPodruzhka/getPodrOrders", async (_, { dispatch, getState }) => {
  const state = getState()
  const orders = state.updateStoksPodruzhka.orders
  if (orders) {
    return
  }
  const response = await BaseApi.podruzhkaOrders()
  dispatch(setPodruzhkaOrders(response.data?.data))
})

export const PodrOrders: FC = () => {
  const dispatch = useAppDispatch()
  const updateStoksStore = useAppSelector(selectUpdateStoksPodruzhkaStore)
  const { orders } = updateStoksStore
  const [selectedOrder, setSelectedOrder] = useState<string>("")
  useEffect(() => {
    dispatch(getPodrOrders())
  }, [])

  const ordersList = Object.keys(orders || {})
  return (
    <div>
      <h5>Заказы Подружка</h5>
      <select
        name="order-podruzhka"
        id="order-podruzhka"
        value={selectedOrder || ""}
        onChange={(e) => {
          setSelectedOrder(e.target.value)
        }}
      >
        <option value={""}>Выбрать дату заказа</option>
        {ordersList.map((date) => (
          <option value={date} key={date}>
            {new Date(+date).toLocaleString()}
          </option>
        ))}
      </select>
      <div>
        <table>
          <thead>
            <tr>
              <th>Артикул подружки</th>
              <th>Название</th>
              <th>количество</th>
              <th>цена</th>
            </tr>
          </thead>
          <tbody>
            {orders?.[selectedOrder]?.items.map((el, i) => {
              return (
                <tr key={el.id + "-" + i}>
                  <td>{el.id}</td>
                  <td>{el.name}</td>
                  <td>{el.quantity}</td>
                  <td>{Number.parseFloat(el.price)}</td>
                </tr>
              )
            })}
            {
              <tr key={"summary"}>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <b>{orders?.[selectedOrder]?.value}</b>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
