import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = PodrStateType & {
  orders?: PodrOrdersType
}

export type PodrOrdersType = {
  [key: string]: {
    items: {
      id: string
      name: string
      brand: string
      quantity: number
      category: string
      price: string
      discount: number
    }[]
    value: number
  }
}

type PodrStateType = {
  wildberries: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status: "Ошибка" | "Успешно" | "Ошибка в файлах" | "В процессе"
    isCurrentDataUploaded: boolean
  }
}

const initialState: initialStateType = {
  wildberries: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    status: "Ошибка в файлах",
    isCurrentDataUploaded: false,
  },
}

const updateStoksPodruzhkaSlice = createSlice({
  name: "updateStoksPodruzhka",
  initialState,
  reducers: {
    setUpdateStoksPodruzhkaState: (
      state,
      action: PayloadAction<PodrStateType>,
    ) => {
      state.wildberries = action.payload.wildberries
    },
    setPodruzhkaOrders: (state, action: PayloadAction<PodrOrdersType>) => {
      state.orders = action.payload
    },
  },
})

export const selectUpdateStoksPodruzhkaStore = (state: RootState) =>
  state.updateStoksPodruzhka

export default updateStoksPodruzhkaSlice.reducer

export const { setUpdateStoksPodruzhkaState, setPodruzhkaOrders } =
  updateStoksPodruzhkaSlice.actions
